/* Container holding the image and the text */
.container {
  position: relative;
  height: 100%;
}
.container h3 {
  font-family: 'Damion', cursive;
  color: #fff;
  text-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30);
}
.container h2 {
  font-family: 'Damion', cursive;
  color: #fff;
  text-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30);
}

.bgInitial{
  background:url('./assets/emoji/BG.png');
  background-position: initial;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.bg{
  background:url('./assets/emoji/BG.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.bgPortrait{
  background:url('./assets/emoji/BGPortrait.png');
  background-position: initial;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.containerPortrait {
  position: relative;
  height: 76%;
}
.containerPortrait h3 {
  font-family: 'Damion', cursive;
  color: #fff;
  text-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30);
}
.containerPortrait h2 {
  font-family: 'Damion', cursive;
  color: #fff;
  text-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30);
}

.containerPortraitNew {
  position: absolute;
  height: 135%;
  transform: rotate(-90deg);
}
.containerPortraitNew h3 {
  font-family: 'Damion', cursive;
  color: #fff;
  text-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30);
}
.containerPortraitNew h2 {
  font-family: 'Damion', cursive;
  color: #fff;
  text-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30);
}

.downloadContainer {
  position: relative;
  display: none;
  /* height: 100%; */
}
.downloadContainer h3 {
  font-family: 'Damion', cursive;
  color: #fff;
  text-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30);
}
.downloadContainer h2 {
  font-family: 'Damion', cursive;
  color: #fff;
  text-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30);
}


h1 {
  font-size: 50px;
}

p {
  font-size: 18px;
}
p small {
  font-size: 80%;
  color: #666;
}

/* The .image-container class needs to be applied to a div wrapped around the image you want to apply this filter to */
.image-container {
  display: inline-block;
  position: relative;
  line-height: 0;
  border: 15px solid transparent;
  border-image: url('./assets/logo/border.png') 30 stretch;
}

.qr{
  position: absolute;
  bottom:15px;
  right:15px;
  /* width:5%; */
}

.qrPortraitNew{
  position: absolute;
  top:15px;
  right:15px;
  transform: rotate(-90deg);
}

.logo{
  position: absolute;
  top:20px;
  right:30px;
  width:15%;
}

.logoFull{
  position: absolute;
  bottom:130px;
  right:20px;
  width:25%;
}


.sparkleFrame1{
  position: absolute;
  top:1%;
  left:1%;
  height:97%;
  z-index:999;
  opacity:0.75;
}
.sparkleFrame2{
  position: absolute;
  bottom:2%;
  left:2%;
  height:90%;
  z-index:999;
  opacity:0.5;
}


.starEmoji1{
  position: absolute;
  top:2%;
  left:2%;
  width:14%;
}

.starEmoji2{
  position: absolute;
  bottom:21%;
  right:2%;
  width:14%;
}

.faceEmoji1{
  position: absolute;
  top:1%;
  right:3%;
  width:11%;
}

.faceEmoji1Portrait{
  position: absolute;
  top:8px;
  right:50px;
  width: 10%;
}

.faceEmoji1PortraitNew{
  position: absolute;
  top:1%;
  right:2%;
  width:10%;
}

.faceEmoji2{
  position: absolute;
  bottom:11%;
  right:1%;
  width:15%;
}

.faceEmoji2Portrait{
  position: absolute;
  bottom:100px;
  right:20px;
  width:15%;
}

.faceEmoji2PortraitNew{
  position: absolute;
  bottom:7%;
  right:2%;
  width:15%;
}

.heartEmoji1{
  position: absolute;
  top:4%;
  right:41%;
  width:7%;
  transform:rotate(-20deg);
}

.heartEmoji1Portrait{
  position: absolute;
  top: 35px;
  right:580px;
  width:7%;
  transform:rotate(-20deg);
}

.heartEmoji1PortraitNew{
  position: absolute;
  top: 3%;
  right:41%;
  width:7%;
  transform:rotate(-20deg);
}

.heartEmoji2{
  position: absolute;
  top:17%;
  right:3%;
  width:7%;
  transform: rotate(15deg);
}

.heartEmoji2Portrait{
  position: absolute;
  top: 245px;
  right:30px;
  width: 7%;
  transform: rotate(15deg);
}

.heartEmoji2PortraitNew{
  position: absolute;
  top: 15%;
  right:3%;
  width: 7%;
  transform: rotate(15deg);
}

.heartEmoji3{
  position: absolute;
  top:15%;
  left:5%;
  width:8%;
  transform: rotate(-15deg);
}

.heartEmoji3Portrait{
  position: absolute;
  top: 215px;
  left:55px;
  width:8%;
  transform: rotate(-15deg);
}

.heartEmoji3PortraitNew{
  position: absolute;
  top: 13%;
  left:5%;
  width:8%;
  transform: rotate(-15deg);
}

.story{
  position: absolute;
  bottom:15%;
  left:1%;
  width: 60%;
  transform: rotate(-10deg);
}

.gift{
  position: absolute;
  top:7%;
  right:1%;
  width:41%;
  transform: rotate(15deg);
}

.giftPortrait{
  position: absolute;
  top: 79px;
  right:13px;
  width:41%;
  transform: rotate(13deg);
}

.giftPortraitNew{
  position: absolute;
  top: 6%;
  right:1%;
  width:41%;
  transform: rotate(13deg);
}

.emoji4{
  position: absolute;
  bottom: 330px;
  right:30px;
  width: 20%;
}

.emoji5{
  position: absolute;
  bottom:75px;
  left:230px;
  width: 20%;
}

.hash-block {
  position: absolute;
  top: 30px;
  right:-15px;
  color: #f1f1f1; 
  padding: 20px;
}


/* Bottom right text */
.msgBlock {
  position: absolute;
  bottom: 30px;
  color: #BA9F74;
  background: #FFFAEC;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 18px;
}

.title {
  /* --lineHeight: 5em; */
  margin-bottom:5%;
  position: absolute;
  bottom: 1%;
  color: #BA9F74;
  padding: 10px;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: bold;
  width:97%;
}

.title span {
  line-height: 175%;
  padding: 2px 5px;
  /* background: #FFFAEC 0/100% calc(var(--lineHeight)/4) no-repeat; */
  background: #FFFAEC;
}

.defaultMsg {
  color: #ffffff;
  font-family: sans-serif;
  font-size: 55px;
  font-weight: bold;
  line-height: 175%;
}

.canvImg {
  border: 15px solid transparent;
  border-image: url('./assets/logo/border.png') 30 stretch;
}

.txtContainer {
  position: absolute;
  top: 80px;
  right:255px;
  line-height:1.5;
  text-align:center;
}

.highlightContainer {
  position: absolute;
  background: #f56cf5;
  transform: rotate(15deg);
  padding: 4px;
  font-size:20px;
  font-family: Georgia, serif;
  font-weight: bold;
  letter-spacing:3px;
  color: #fff;
}

.flex1{
  flex: 1;
}

.flex2{
  flex: 6;
}

.flex3{
  flex:1;
}
